@import "src/Base/assets/styles/colors.module";

.app {
  position: fixed;
  //background: $background-app;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
